<template>
  <CRow>
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader>
          {{
          $route.params.id == 0
          ? "Nuevo Grupo"
          : "Grupo: " + $route.params.id
          }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol xs="12" md="6">
              <CInput label="Nombre" placeholder="Nombre" v-model="form.name" required />
            </CCol>
            <CCol xs="12" md="6" class="text-right">
              <CButton
                color="primary"
                @click="
                  () => {
                    selector = true;
                  }
                "
              >
                <font-awesome-icon icon="plus" />
                Add product
              </CButton>
              <CDataTable hover striped :items="products" :fields="fields">
                <template #Price="data">
                  <td>
                    {{ formatPrice(data.item.Price) }}
                  </td>
                </template>
                <template #ItemCode="data">
                  <td>
                    <CButton
                      class="cartAdd"
                      color="danger"
                      size="sm"
                      variant="ghost"
                      @click="deleteLine(data.item)"
                      ><font-awesome-icon icon="trash" />
                    </CButton>
                    {{ data.item.ItemCode }}
                  </td>
                </template>
              </CDataTable>
            </CCol>

          </CRow>
        </CCardBody>
        <CCardFooter>
          <CRow class="mt-2">
            <CCol sm="12" md="6">
              <CButton color="primary" @click="goBack">Back</CButton>
            </CCol>
            <CCol sm="12" md="6" class="text-right">
              <CButton color="primary" @click="save" v-if="!loading">Guardar</CButton>
              <div class="text-right" v-if="loading">
                <CSpinner variant="grow" size="lg" />
              </div>
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>
    </CCol>
    <CModal
      title="Modal title"
      :show.sync="selector"
      :centered="true"
      size="lg"
      color="dark"
    >
      <CDataTable
        hover
        striped
        :items="productsSelector"
        :fields="fieldsSelector"
        clickable-rows
        @row-clicked="rowSelectorClicked"
      >
        <template #Price="data">
          <td>
            {{ formatPrice(data.item.Price) }}
          </td>
        </template>
      </CDataTable>
    </CModal>

    <CToaster :autohide="3000">
      <template v-for="(item, index) in toasts">
        <CToast :key="`${item.title}-${index}`" :show="true" :color="item.color" :header="item.title">
          {{ item.message }}
        </CToast>
      </template>
    </CToaster>
  </CRow>
</template>

<script>
import Resource from "@/api/resource";

const resource = new Resource("productgroup");
const resourceItems = new Resource("items");

export default {
  name: "Group",
  data() {
    return {
      loading: false,
      selector: false,
      toasts: [],
      opened: null,
      form: {
        name: null,
      },
      items: [],
      courses: [],
      products: [],
      productsSelector: [],
      fields: [
        { key: "ItemCode", label: "Codigo" },
        { key: "ItemName", label: "Nombre" },
      ],
      fieldsSelector: [
        { key: "ItemCode", label: "Codigo" },
        { key: "ItemName", label: "Nombre" },
      ],
    };
  },
  async mounted() {
    await this.loadItems();
    if (this.$route.params.id != 0) {
      await this.getItem();
    }
  },
  methods: {
    rowSelectorClicked(item) {
      var existItem = this.products.find(
        (element) => element.ItemCode === item.ItemCode
      );
      if (existItem) {
        this.selector = false;
      } else {
        this.products.push(item);
        this.selector = false;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    async save() {
      this.loading = true;
      try {
        this.form.products = this.products;
        if (this.$route.params.id == 0) {
          const response = await resource.store(this.form);
          if (response.status == 200 && response.data.status == "OK") {
            this.form = response.data;
            this;
            this.toasts.push({
              title: "Confirmación",
              color: "success",
              message: "Guardado con exito",
            });
          } else {
            throw response.data.message;
          }
        } else {
          const response = await resource.update(
            this.$route.params.id,
            this.form
          );
          if (response.status == 200 && response.data.status == "OK") {
            this.form = response.data;
            this;
            this.toasts.push({
              title: "Confirmación",
              color: "success",
              message: "Grupo actualizado con exito",
            });
          } else {
            throw response.data.message;
          }
        }
        this.goBack();
      } catch (error) {
        this.toasts.push({
          title: "Error",
          color: "danger",
          message: error,
        });
      } finally {
        this.loading = false;
      }
    },
    async getItem() {
      this.loading = true;
      try {
        const response = await resource.get(this.$route.params.id);
        if (response.status == 200) {
          this.form = response.data.data;
          this.productsSelector.forEach(product => {
            this.form.products.forEach(selected => {
              if(product.ItemCode === selected.itemcode){
                this.products.push(product);
              }
            });            
          });
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async loadItems() {
      this.loading = true;
      await resourceItems
        .list()
        .then((response) => {
          var clean = response.data.filter(
            (arr, index, self) =>
              index === self.findIndex((t) => t.ItemCode === arr.ItemCode)
          ).filter(function(item){
            return !item.ItemCode.includes('DST');
          });
          this.productsSelector = clean;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteLine(item) {
      this.products.splice(this.products.indexOf(item), 1);
    },
  },
};
</script>
